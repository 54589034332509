import React, { useEffect, useState } from "react";
import "../../../../../assets/scss/components/page/public/Page.scss";
import "../../../../../assets/scss/Common.scss";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../../../../ui-component/page/public/section";
import Page from "../../../../../ui-component/page/public";
import { get as getMain } from "../../../../../store/actions/three_d_models/files/three_d_models_files";
import {
  get as getFile,
  getAll as getFiles,
} from "../../../../../store/actions/three_d_models/files/three_d_models_files_files";
import { Parser as HtmlToReactParser } from "html-to-react";
import {
  getData as getFileData,
  getFilePath,
} from "../../../../../store/actions/general/file";

const ThreeDModelsFiles = () => {
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const main = useSelector((state) => state.threeDModelsFiles.data);
  const list = useSelector((state) => state.threeDModelsFilesFiles.list);
  const htmlToReactParser = new HtmlToReactParser();
  const fileData = useSelector((state) => state.file.fileData);

  useEffect(() => {
    getMain(dispatch);
    getFiles(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      for (let data of list) {
        getFile(dispatch, data.id);
      }
    }
  }, [list, dispatch]);

  useEffect(() => {
    if (list) {
      for (let item of list) {
        getFileData(dispatch, item.file);
      }
    }
  }, [list, dispatch]);

  if (!list || !main) {
    return <></>;
  }

  return (
    <Page
      id={"three3ModelsFiles"}
      type={"style1"}
      title={contents ? contents["drawing_files"] : ""}
    >
      <Section>
        <div className={"left-side"}>
          <p className={"title"}></p>
        </div>
        <div className={"right-side"}>
          <div className={"content"}>{htmlToReactParser.parse(main.info)}</div>
        </div>
      </Section>
      <Section>
        <div className={"left-side"}>
          <p className={"title"}>Files</p>
        </div>
        <div className={"right-side"}>
          <div className={"files"}>
            {list.map((file, i) => {
              if (!file) {
                return <></>;
              }

              if (!fileData[file.file]) {
                return <></>;
              }

              return (
                <div className={"file"} key={i} style={{ marginBottom: "2em" }}>
                  <a
                    className={"title"}
                    href={getFilePath(fileData[file.file])}
                    target={"_blank"}
                  >
                    {file.name}
                  </a>
                  <div className={"subTitle"}>
                    ({(fileData[file.file].file.size / (1024 * 1024)).toFixed(2)} KB)
                  </div>
                  <div className={"detail"}>
                    {htmlToReactParser.parse(file?.info)}
                  </div>
                  <a
                    className={"download"}
                    href={getFilePath(fileData[file.file])}
                    target={"_blank"}
                  >
                    {contents
                      ? contents["download_click_here_to_download"]
                      : ""}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </Page>
  );
};

export default ThreeDModelsFiles;
