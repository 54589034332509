import React, {useEffect} from "react";
import "../../../../assets/scss/views/public/home/NewsEvents.scss";
import {useDispatch, useSelector} from "react-redux";
import {getAll as getAllEvents} from "../../../../store/actions/news_events/events";
import {getAll as getAllNews} from "../../../../store/actions/news_events/news";
import {useNavigate} from "react-router-dom";
import {getData, getImagePath} from "../../../../store/actions/general/image";
import {getSimpleDate} from "../../../../util/date_util";

function Event({event}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (event.image) {
            getData(dispatch, event.image);
        }
    }, [event?.image]);

    const handleKeyUp = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/events/detail", {
                state: {
                    event: event
                }
            });
        }
    };

    const handleClick = () => {
        navigate("/events/detail", {
            state: {
                event: event
            }
        });
    };

    if (imageData[event.image]) {
        return <div className={"item clickable"} role={"button"} tabIndex={0} onClick={handleClick}
                    onKeyUp={handleKeyUp}>
            <img alt={imageData[event.image].name} src={getImagePath(imageData[event.image])}/>
            <div className="info">
                <p className="date">{getSimpleDate(event.start_date)} - {getSimpleDate(event.end_date)}</p>
                <p className="title">{event.name}</p>
            </div>
        </div>
    } else {
        return <div className={"item clickable"} role={"button"} tabIndex={0} onClick={handleClick}
             onKeyUp={handleKeyUp}>
            <div className="info">
                <p className="date">{getSimpleDate(event.start_date)} - {getSimpleDate(event.end_date)}</p>
                <p className="title">{event.name}</p>
            </div>
        </div>
    }
}

function News({news}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (news.image) {
            getData(dispatch, news.image);
        }
    }, [news?.image]);

    const handleKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/news/detail", {
                state: {
                    news: news
                }
            });
        }
    };

    const handleClick = () => {
        navigate("/news/detail", {
            state: {
                news: news
            }
        });
    };

    return (
        <div className={"item clickable"} role={"button"} tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
            {imageData[news.image] ? <img alt={imageData[news.image].name} src={getImagePath(imageData[news.image])}/> : <></>}
            <div className="info">
                <p className="date">{getSimpleDate(news.date)}</p>
                <p className="title">{news.name}</p>
            </div>
        </div>
    );
}

const NewsEvents = () => {
    const dispatch = useDispatch();

    const events = useSelector((state) => state.event.events);
    const news = useSelector((state) => state.news.news);

    useEffect(() => {
        getAllEvents(dispatch);
        getAllNews(dispatch);
    }, []);

    const eventsParts = [];
    for (let idx in events) {
        let id = parseInt(parseInt(idx)/2);
        if (eventsParts[id] === undefined) {
            eventsParts[id] = []
        }

        eventsParts[id].push(events[idx]);
    }

    const newsParts = [];
    for (let idx in news) {
        let id = parseInt(parseInt(idx)/2);
        if (newsParts[id] === undefined) {
            newsParts[id] = []
        }

        newsParts[id].push(news[idx]);
    }

    return (
        <div className={"news-events"}>
            {
                eventsParts.length > 0 ?
                    eventsParts.map((part, i) => {
                        return (
                            <div className={"row"} key={i}>
                                {
                                    part.length > 0 ? part.map((item, j) => {
                                        return <Event event={item} key={j}></Event>;
                                    }) : <></>
                                }
                            </div>
                        );
                    }) : <></>
            }
            {
                newsParts.length > 0 ?
                    newsParts.map((part, i) => {
                        return (
                            <div className={"row"} key={i}>
                                {
                                    part.length > 0 ? part.map((item, j) => {
                                        return <News news={item} key={j}></News>;
                                    }) : <></>
                                }
                            </div>
                        );
                    }) : <></>
            }
        </div>
    );
};

export default NewsEvents;